import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    maxWidth: 450,
    paddingTop: 10,
  },
}));
