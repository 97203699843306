import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    padding: 5,
  },
  listItem: {
    paddingInlineStart: 30,
    color: COLORS_REDESIGN.NIGHT_GREY,
  },
  rootHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 15,
    marginRight: 30,
  },
  contentRoot: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
}));
