import {observer} from 'mobx-react-lite';

import {Button, Typography} from '@mui/material';
import {Modal} from '@src/components/common/modal/modal';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';
import {yandexMetrika} from '@src/shared/utils/yandex-metrika';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './ibereg-modal.s';

export const IBeregModal = observer(() => {
  const {utils} = useStore();
  const {classes} = useEmotionWrapper();

  return (
    <Modal hideTitle opened={utils.isOpenIberegModal} onClose={() => utils.closeIberegModal()}>
      <div className={classes.root}>
        <div className={classes.rootHeader}>
          <Typography fontSize={24} fontWeight={700} sx={{color: COLORS_REDESIGN.DARK_NIGHT_BLUE}}>
            Биддер для OZON
          </Typography>
          <img draggable={false} width={105} height={30} src="/png/ibereg-logo.png" alt="ibereg-logo" />
        </div>
        <div className={classes.contentRoot}>
          <div>
            <Typography sx={{color: COLORS_REDESIGN.NIGHT_GREY}}>
              Cервис аналитики и <br /> автоматизированных инструментов <br /> для развития бизнеса на OZON
            </Typography>
            <ul className={classes.listItem}>
              <li>АвтоБиддер</li>
              <li>Анализ позиции товара</li>
              <li>Визор мониторинга конкурентов</li>
            </ul>
          </div>
          <img draggable={false} width={120} height={162} src="/png/ibereg-robot.png" alt="ibereg-logo" />
        </div>
        <Button
          onClick={() => {
            yandexMetrika().targetFulfilled.clickButtonIbereg();
            window.open(RESOURCE_URLS.IBEREG_UTM_URL, '_blank');
            utils.closeIberegModal();
          }}
          variant="contained"
        >
          Начать пользоваться биддером для OZON
        </Button>
      </div>
    </Modal>
  );
});
