import {CURRENT_MP_ACCOUNT, REFERRAL_ID} from '../const/localStorage';

export function checkAndStoreQueryParams() {
  const queryParams = new URLSearchParams(window.location.search);

  const referralId = queryParams.get('ref');

  if (referralId) {
    sessionStorage.setItem(REFERRAL_ID, referralId);
  }

  if (queryParams.has(CURRENT_MP_ACCOUNT)) {
    const value = queryParams.get(CURRENT_MP_ACCOUNT);

    if (value !== null) {
      localStorage.setItem(CURRENT_MP_ACCOUNT, value);
    }
  }
}
