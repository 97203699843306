import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import LoadingButton from '@mui/lab/LoadingButton';
import {Typography} from '@mui/material';
import {Modal} from '@src/components/common/modal';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {IS_VIEWED_INVITE_TO_PARTNERS_MODAL} from '@src/shared/const/localStorage';
import {useStore} from '@src/stores';

export const InviteToPartnerModal = observer(() => {
  const {user, utils} = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const isViewed = localStorage.getItem(IS_VIEWED_INVITE_TO_PARTNERS_MODAL) === '1';

  const onCloseModal = () => {
    utils.closeInviteToPartnerModal();
  };

  const onClickInvite = async () => {
    setIsLoading(true);
    await new Promise((resolve) => {
      setTimeout(() => {
        setIsLoading(false);
        onCloseModal();
        resolve(true);
      }, 1000);
    });
    toast.success('Мы рады, что вы приняли наше приглашение. Менеджер партнерской программы скоро свяжется с вами');
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (user.canBecomePartner) {
      if (!isViewed) {
        intervalId = setTimeout(() => {
          utils.openInviteToPartnerModal();
        }, 1000 * 60);
        utils.openInviteToPartnerModal();
      }
    }

    return () => {
      intervalId && clearTimeout(intervalId);
    };
  }, [user.canBecomePartner]);

  return (
    <Modal
      title="Приглашаем стать нашим Партнером!"
      isCloseOnClickOverlay={false}
      opened={utils.isOpenInviteToPartnerModal}
      onClose={onCloseModal}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          maxWidth: 470,
          textAlign: 'justify',
          alignItems: 'flex-start',
        }}
      >
        <Typography sx={{color: COLORS_REDESIGN.NIGHT_GREY, fontSize: 14}}>
          Мы заметили, что вы пригласили уже 3 новых пользователей, которые активно пользуются нашим сервисом по вашей
          рекомендации. Хотим выразить вам свою благодарность и пригласить стать нашим Партнером.
        </Typography>
        <Typography sx={{color: COLORS_REDESIGN.NIGHT_GREY, fontSize: 14}}>
          Партнер получает больше вознаграждений за новых пользователей!
        </Typography>
        <LoadingButton onClick={onClickInvite} loading={isLoading} sx={{marginTop: '10px'}} variant="contained">
          Хочу стать Партнером
        </LoadingButton>
      </div>
    </Modal>
  );
});
