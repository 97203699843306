import {observer} from 'mobx-react-lite';

import {ChangelogModal} from '@src/components/specific/modals/changelog-modal/changelog-modal';
import {ReferralModal} from '@src/components/specific/modals/referral-modal/referral-modal';
import {UserContactsModal} from '@src/components/specific/modals/user-contacts/user-contacts';
import {useMediaQuery} from '@src/hooks/useMediaQuery';

import {AccountingInfoModal} from '../accounting-info-modal/accounting-info-modal';
import {IBeregModal} from '../ibereg-modal/ibereg-modal';
import {InviteToPartnerModal} from '../invite-to-partner-modal/invite-to-partner-modal';

export const ModalsManager = observer(() => {
  const {isScreenNarrow} = useMediaQuery();
  return (
    <>
      <UserContactsModal />
      {!isScreenNarrow && <ChangelogModal />}
      {!isScreenNarrow && <ReferralModal />}
      {!isScreenNarrow && <AccountingInfoModal />}
      {!isScreenNarrow && <InviteToPartnerModal />}
      {!isScreenNarrow && <IBeregModal />}
    </>
  );
});
