import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/ru';

const initializeDayjs = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  dayjs.extend(timezone);
  dayjs.extend(isAfter);
  dayjs.extend(duration);
  dayjs.tz.setDefault(userTimeZone);
  dayjs.locale('ru');
};

export default initializeDayjs;
