import {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import LoadingButton from '@mui/lab/LoadingButton';
import {Link, TextField} from '@mui/material';
import {Modal} from '@src/components/common/modal/modal';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';
import {IS_CLOSED_ACCOUNTING_MODAL} from '@src/shared/const/session-storage';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './accounting-info-modal.s';

export const AccountingInfoModal = observer(() => {
  const {classes} = useEmotionWrapper();
  const {user, utils, mpAccounts} = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [newAccountingData, setNewAccountingData] = useState({
    inn: '',
    fullName: '',
    phone: '',
    edoOperator: '',
  });
  const isClosedAccountingView = sessionStorage.getItem(IS_CLOSED_ACCOUNTING_MODAL) === '1';

  useEffect(() => {
    if (
      mpAccounts.current?.accountingInfo.isRequired &&
      mpAccounts.current.accountingInfo.data === null &&
      !user.data.isModerator &&
      !isClosedAccountingView
    ) {
      utils.openAccountingModal();
    }
  }, [mpAccounts.current?.accountingInfo.isRequired]);

  useEffect(() => {
    if (utils.isOpenAccountingModal && mpAccounts.current?.accountingInfo.data !== null && mpAccounts.current) {
      setNewAccountingData({
        inn: mpAccounts.current.accountingInfo.data.inn?.toString() || '',
        fullName: mpAccounts.current.accountingInfo.data.fullName || '',
        phone: mpAccounts.current.accountingInfo.data.phone || '',
        edoOperator: mpAccounts.current.accountingInfo.data.edoOperator || '',
      });
    }
    if (!utils.isOpenAccountingModal) {
      setNewAccountingData({
        inn: '',
        fullName: '',
        phone: '',
        edoOperator: '',
      });
    }
    return () => {
      setNewAccountingData({
        inn: '',
        fullName: '',
        phone: '',
        edoOperator: '',
      });
    };
  }, [mpAccounts.current?.accountingInfo, utils.isOpenAccountingModal]);

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      if (mpAccounts.current?.id) {
        const updateData = {
          marketPlaceAccountId: mpAccounts.current?.id,
          accountingInfo: {
            inn: Number(newAccountingData.inn),
            fullName: newAccountingData.fullName,
            phone: newAccountingData.phone,
            edoOperator: newAccountingData.edoOperator,
          },
        };

        if (mpAccounts.current.accountingInfo.data === null) {
          await mpAccounts.addAccountingInfo(updateData);
        } else {
          await mpAccounts.updateAccountingInfo(updateData);
        }

        await mpAccounts.getMpAccountDetails(mpAccounts.current?.id);
      }

      utils.closeAccountingModal();
    } catch (e) {
      toast.error('Произошла ошибка');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={
        <MTypography sx={{fontWeight: 700, fontSize: 20}}>
          Укажите данные о компании и контакты <br /> ответственного за документооборот
        </MTypography>
      }
      // hideCloseButton={
      //   mpAccounts.current && mpAccounts.current.accountingInfo.isRequired
      //     ? mpAccounts.current.accountingInfo.data === null
      //     : false
      // }
      isCloseOnClickOverlay={false}
      opened={utils.isOpenAccountingModal}
      onClose={utils.closeAccountingModal}
    >
      <div className={classes.root}>
        {mpAccounts.current && mpAccounts.current.accountingInfo.data === null && (
          <MTypography sx={{fontWeight: 400, fontSize: 14}}>
            При необходимости вы можете изменить их в Настройках
          </MTypography>
        )}
        <TextField
          required
          fullWidth
          label="ИНН вашей компании"
          value={newAccountingData.inn}
          onChange={(e) => setNewAccountingData({...newAccountingData, inn: e.target.value})}
        />
        <TextField
          required
          fullWidth
          label="ФИО ответственного за документооборот"
          value={newAccountingData.fullName}
          onChange={(e) => setNewAccountingData({...newAccountingData, fullName: e.target.value})}
        />
        <InputMask
          mask="+7 999 999 99 99"
          value={newAccountingData.phone}
          placeholder="Телефон ответственного за документооборот"
          onChange={(e) => setNewAccountingData({...newAccountingData, phone: e.target.value})}
        >
          {() => <TextField required fullWidth type="tel" label="Телефон ответственного за документооборот" />}
        </InputMask>
        <TextField
          fullWidth
          required
          label="Оператор ЭДО"
          value={newAccountingData.edoOperator}
          onChange={(e) => setNewAccountingData({...newAccountingData, edoOperator: e.target.value})}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          loading={isLoading}
          onClick={onClickSave}
          disabled={
            !newAccountingData.inn.length ||
            !newAccountingData.fullName.length ||
            !newAccountingData.phone.length ||
            !newAccountingData.edoOperator.length
          }
        >
          Отправить
        </LoadingButton>
        <MTypography sx={{fontSize: 14, fontWeight: 400, color: COLORS_REDESIGN.NIGHT_GREY}}>
          Нажимая Отправить, вы даете согласие на обработку <br />{' '}
          <Link href={RESOURCE_URLS.PRAVO}>персональных данных</Link>
        </MTypography>
      </div>
    </Modal>
  );
});
