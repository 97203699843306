import {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';

import {ThemeProvider} from '@emotion/react';
import {StyledEngineProvider} from '@mui/material';
import {
  AppContainer,
  AppRouter,
  AuthWrapper,
  ChatraInitialize,
  ErrorBoundaryWrapper,
  MaintenanceMode,
  UserOnboardingTour,
} from './components';
import {StoreProvider} from './stores';

import {ConfirmationDialogProvider} from './components/providers/confirm-dialog/confirm-dialog';
import {ToastContainer} from './components/providers/toast-container/toast-container';
import {WebSocketProvider} from './components/providers/websocket/websocket-provider';
import {InitYandexMetrika} from './components/specific/init-yandex-metrika/init-yandex-metrika';
import {MobileScreenPlaceholder} from './components/specific/mobile-screen-placeholder/mobile-screen-placeholder';
import {ModalsManager} from './components/specific/modals/modals-manager/modals-manager';
import initializeDayjs from './shared/configs/dayjsConfig';
import initializeSentry from './shared/configs/sentryConfig';
import {WEBSOCKET_PRIVATE_CHANNEL, WEBSOCKET_PUBLIC_CHANNEL} from './shared/const/websocket';
import {EmotionProvider} from './shared/lib';
import {globalStyles} from './shared/styles/globalStyles';
import theme from './shared/styles/theme';
import {checkAndStoreQueryParams} from './shared/utils/check-and-store-query-params';

initializeSentry();
initializeDayjs();

function App() {
  useEffect(() => {
    checkAndStoreQueryParams();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <EmotionProvider withNormalize global={globalStyles}>
          <ErrorBoundaryWrapper>
            <BrowserRouter>
              <StoreProvider>
                <MobileScreenPlaceholder>
                  <UserOnboardingTour>
                    <ChatraInitialize />
                    <InitYandexMetrika />
                    <MaintenanceMode>
                      <WebSocketProvider urls={{private: WEBSOCKET_PRIVATE_CHANNEL, public: WEBSOCKET_PUBLIC_CHANNEL}}>
                        <ToastContainer />
                        <AuthWrapper>
                          <ConfirmationDialogProvider>
                            <ModalsManager />
                            <AppContainer>
                              <AppRouter />
                            </AppContainer>
                          </ConfirmationDialogProvider>
                        </AuthWrapper>
                      </WebSocketProvider>
                    </MaintenanceMode>
                  </UserOnboardingTour>
                </MobileScreenPlaceholder>
              </StoreProvider>
            </BrowserRouter>
          </ErrorBoundaryWrapper>
        </EmotionProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
